import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import INSTATGRAM from "../assets/images/Instagram.svg";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";

import GameCare from "../assets/images/icons/gamecura.png";
import ExchangeIconUpdated from "../assets/images/icons/exchange.svg";
import AviatorIconUpdated from "../assets/images/icons/aviator.png";
import SportsIconUpdated from "../assets/images/icons/Sportsbook.svg";
import HomeIconUpdated from "../assets/images/icons/home.svg";
import CasinoIconUpdated from "../assets/images/icons/casino.svg";
import DepositIconUpdated from "../assets/images/icons/user.svg";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();

  const [activePage, setActivePage] = useState("");
  const footerPages = [
    "betby",
    "aviator",
    "/sports/Inplay",
    "home",
    "/livecasino",
    "/dashboard",
    "/",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} sm={4} md={4} className="">
            <div className="foot-logo text-center">
              <a href="/">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              </a>
            </div>
            <div className="footlogoCare">
              <img src={GameCare} alt="Logo" />
            </div>
          </Col>

          <Col xs={12} sm={4} md={3}>
            <div className="quick-links">
              <h5>Company</h5>
              <ul>
                <li>
                  <a href="/contactus">Contact Us</a>
                </li>
                <li>
                  <a href="/privacypolicy ">Privacy Policy</a>
                </li>
                <li>
                  <a href="/responsiblegame">Responsible Gaming</a>
                </li>
                <li>
                  <a href="/fairplay">Fair Play</a>
                </li>
                <li>
                  <a href="/gamesrule">Games Rules</a>
                </li>
                <li>
                  <a href="/terms">Terms and Conditions</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={4} md={4}>
            <div className="contactus">
              <h5>Connect with us </h5>
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={INSTATGRAM} alt="email" />
                    <span></span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="text-center">
            <p className="mt-4 mb-0">
              Disclaimer:
              <br />
              Please note that Gambling involves a financial risk and could be
              addictive over time if not practised within limits. Only 18+
              people should use the services and should use it responsibly.
              Players should be aware of any financial risk and govern
              themselves accordingly.
            </p>
          </Col>
        </Row>
        <p className="copyRight">
          Copyrights 2024, Baba333 | All rights reserved
        </p>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li className="exchange">
              <a
                className={activePage === "/sports/Inplay" ? "active" : ""}
                href="/sports/Inplay"
              >
                <div className="imgBox">
                  <img src={ExchangeIconUpdated} alt="SportsIcon" />
                </div>
                <span>Exchange</span>
              </a>
            </li>
            <li className="aviator">
              <a
                className={activePage === "/aviator" ? "active" : ""}
                href="/casino/spribe/aviator"
              >
                <div className="imgBox">
                  <img src={AviatorIconUpdated} alt="AviatorIcon" />
                </div>
                <span>Aviator</span>
              </a>
            </li>
            {/* <li className="betby">
              <a
                className={activePage === "/betby" ? "active" : ""}
                href="/betby"
              >
                <div className="imgBox">
                  <img src={SportsIconUpdated} alt="SportsIcon" />
                </div>
                <span>Sportsbook</span>
              </a>
            </li> */}
            <li className="home">
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <div className="imgBox">
                  <img src={HomeIconUpdated} alt="SportsIcon" />
                </div>
                <span>Home</span>
              </a>
            </li>
            <li className="casino">
              <a
                href="/livecasino"
                className={activePage === "/livecasino" ? "active" : ""}
              >
                <div className="imgBox">
                  <img src={CasinoIconUpdated} alt="SportsIcon" />
                </div>
                <span>Casino</span>
              </a>
            </li>
            <li className="deposit">
              <a
                className={activePage === "/dashboard" ? "active" : ""}
                href="/dashboard"
              >
                <div className="imgBox">
                  <img src={DepositIconUpdated} alt="SportsIcon" />
                </div>
                <span>Profile</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
